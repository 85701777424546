import React from 'react';
import Layout from "../components/Layout"
import PricingComponent from "../components/Pricing"

import styled from "styled-components"
import tw from "tailwind.macro"
import { graphql } from "gatsby"
import Support from "../components/Home/Support";

const Container = styled.div`
  ${tw`py-20`};
  background-color: ${props => props.theme.colors.background};
`

const Pricing = ({data}) => {

    return (
        <Layout>
            <Container>
                {/*<PricingComponent pricing={data.contentfulHome.pricing} />*/}
                <Support/>
            </Container>
        </Layout>
    )
}

export const query = graphql`
    {
        contentfulHome {
            pricing {
                free {
                    features
                    monthly_price
                    plan
                    yearly_price
                }
                premium {
                    features
                    monthly_price
                    plan
                    yearly_price
                }
            }
        }
    }
`

export default Pricing